<template>
  <section class="mainText ">
    <b-container fluid="xl">
        <b-row class="maintText__row">
        <b-col cols="12" class="mainText__mainName side-gap">

          <h2 style="mainText__mainName--maintxtx">
            Okos vásárláshoz okos szokások
          </h2>
          <p class="hashText">- Hogy ne légy az adathalászok áldozata</p>
        </b-col>
      </b-row>

      <b-row class="maintText__row">
        <b-col cols="12" lg="6" class="side-gap order-0">
          <div class="maintText__txt-content">
            <p class="text">
              Az online vásárlás számos előnnyel bír: gyors, kényelmes és rögtön össze lehet hasonlítani az adott termék árát más áruházak kínálatával. Ám az idill könnyen válhat rémálommá is: rossz terméket kapunk vagy egyáltalán nem is érkezik meg a rendelés, sőt, a kártyaadataink rossz kézbe kerülése esetén még a pénzünk sincs biztonságban! 
            </p>
            <p class="text pb-5"><b>De mi is az az adathalászat?</b><br><br>
              Az adathalászat azt jelenti, hogy megadott adataid illetéktelen kézbe kerülésével visszaélés áldozatává válsz, és kisebb-nagyobb összegeket, vagy akár az összes megtakarításodat is leemelhetik a bankszámládról. Ez bűncselekmény, ami megelőzhető tudatos online jelenléttel és az adataid megadásának átgondolásával. <br><br>Az okos (eszközön való) vásárlás mellett tehát az okos vásárlási szokás elsajátítása is nagyon fontos. <br><br>Hoztunk néhány tippet ennek elérésére.  
            </p>
              
          </div>
        </b-col>
        <b-col cols="12" lg="6" class="side-gap order-4 order-lg-1">
            <img src="@/assets/images/bgr/vasarlas.jpg" class="w-100 margin-top-sm-44"/>
        </b-col>
        <b-col cols="12" lg="10" class="offset-lg-1 side-gap order-lg-2 order-1">
            <p class="text">
              <ul>
                <li class="py-3 d-sm-flex">
                  <span class="number">1</span>
                  <p class="text"><b>Gondold át az árazást! </b><br>Persze jólesik – mind a szívünknek, mind a pénztárcánknak - olcsóbban megrendelni az áhított hajszárítót, ruhát vagy éppen a szerszámkészletet, mégis súlyos árat fizethetünk, ha nem tartjuk szem előtt a „Túl szép, hogy igaz legyen” szabályt. Ha egy terméket szinte ingyen kapunk meg, az valószínűleg annyit is ér, és meglepetéseket tartogathat a soha meg nem érkező csomag, vagy a hirtelen eltűnt összeg a számlánkról. </p> 
                </li>
                <li class="py-3 d-sm-flex">
                  <span class="number">2</span>
                  <p class="text"><b>Ellenőrizd az oldalt, ahol rendelsz </b><br>Érdemes utánanézni más vásárlók véleményének az adott online áruházról, vagy akár ismerősöket megkérdezni, mit találtak eddig megbízhatónak. Néhány webáruháznak van megbízható bolt minősítése is, ami már egy megnyugtató jel lehet. </p>
                </li>
                <li class="py-3 d-sm-flex">
                  <span class="number">3</span>
                  <p class="text"><b>Figyelj, kinek és hol adod meg a bankkártya adataidat!</b><br>Főleg utánvétes fizetést válassz, így nem is kerülnek fel semmilyen rendszerbe a bankkártya adataid, és a termék érkezéséről is meggyőződhetsz fizetés előtt. Ha ez nem opció, semmiképp se add meg e-mailen vagy egyéb üzenetben az adataidat, és preferáld az utalást. Ha mégis meg kell adnod a bankkártya adataidat, tájékozódj előre a lemondási feltételekről. </p>
                </li>
              </ul>
            </p>
        </b-col>
      </b-row>
    </b-container>
    <Articles :isFirst="false"/>
  </section>
</template>
<script>
import Articles from '../components/Articles.vue'

export default {
  components: {
    Articles
  },
  mounted(){

    this.setMetaTags({        
        title: "Okos vásárláshoz okos szokások", 
        desc: "Az online vásárlás számos előnnyel bír: gyors, kényelmes és rögtön össze lehet hasonlítani az adott termék árát más áruházak kínálatával.", 
        keywords: null, 
        ogType: 'website', 
        ogTitle: "Okos vásárláshoz okos szokások",
        ogImage: null, 
        ogImageWidth: null, 
        ogImageHeight: null, 
        ogDesc: "Az online vásárlás számos előnnyel bír: gyors, kényelmes és rögtön össze lehet hasonlítani az adott termék árát más áruházak kínálatával."        
    })
  }
}
</script>